export default {
  clubName: 'راکت',
  logo: 'images/logo.jpeg',
  whatsApp: '989120414116',
  tell: '+989120414116',
  signupRules: [
    {
      value: 'صحت نام و نام خانوادگی را تایید میکنم.'
    },
    {
      value: 'قوانین و مقررات این اپلیکیشن را تایید میکنم.'
    }
  ],
  questionList: [
    {
      question: 'چگونه میتوانم حساب باز کنم؟',
      answer: 'جواب سوال 1',
      show: false
    },
    {
      question: 'برای باز کردن حساب چه مدارکی لازم است؟',
      answer: 'جواب سوال 1',
      show: false
    },
    {
      question: 'من کارت ملی هوشمند دریافت نکرده ام، آیا می توانم حساب باز کنم؟',
      answer: 'جواب سوال 1',
      show: false
    }
  ]
}
