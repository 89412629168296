<template>
  <span v-text="timer > 0 ? `(${timer})` : ''"></span>
</template>

<script>
export default {
  name: 'Timer',
  data () {
    return {
      timer: 0
    }
  },
  model: {
    prop: 'currentTime',
    event: 'changed'
  },
  props: [
    'initTimer',
    'reset',
    'currentTime'
  ],
  mounted () {
    this.timer = this.initTimer
    setInterval(() => {
      if (this.timer > 0) {
        this.timer--
        if (this.timer === 0) this.$emit('changed', true)
      }
    }, 1000)
  },
  watch: {
    reset: {
      handler (val) {
        if (val) {
          this.timer = this.initTimer
          this.$emit('changed', false)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
